angular.module(config.app.name).component('end', {
    templateUrl: 'components/procedure/components/end.component.html',
    bindings: {
        setData: '&'
    },
    controller: function () {
        var self = this;
        self.end = function () {
            self.setData({type: 'end'});
        };
    }
});